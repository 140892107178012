import Cookies from 'js-cookie';
import apiAxios from '../api/apiAxios';

export const criarInscricaoCatequese = (inscricaoCatequeseData) => async (dispatch) => {
  try {
    dispatch({
      type: 'CRIAR_INSCRICAO_CATEQUESE_INICIO',
    });

    const response = await apiAxios.post('/api/matriculasCatequese/inscricao', inscricaoCatequeseData);
    const data = response.data;
    Cookies.set('InscricaoCatequeseData', JSON.stringify(data), { expires: 1 });

    dispatch({
      type: 'CRIAR_INSCRICAO_CATEQUESE_SUCESSO',
      payload: data,
    });

    // Lógica adicional, como redirecionamento ou outras ações, se necessário

  } catch (error) {
    dispatch({
      type: 'CRIAR_INSCRICAO_CATEQUESE_ERRO',
      payload: error.response.data,
    });
  }
};

