import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { matricularAluno } from '../actions/MatriculaAction';
import { formatCPF, formatTelefone } from '../components/Utils';
import escola from '../assets/OIP.png';
import { matricularAlunoEsporte } from '../actions/MatriculaEportesAction';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';

const FormEsportes = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const userDataEsporte = useSelector((state) => state.matriculaEsporte.userInfo);
    const isUserSportLoggedIn = !!userDataEsporte && !!userDataEsporte.data;

    // Estados para os campos do formulário
    const [qualEsporte, setQualEsporte] = useState('');
    const [horario, setHorario] = useState('');
    const [valor, setValor] = useState('');
    const [vencimento, setVencimento] = useState('');

    const [dadosAluno, setDadosAluno] = useState({
        nomeAluno: '',
        CPF: '',
        dataNascimento: '',
        tiposanguinio: '',
        planoDeSaude: '',
        comQuemMora: '',
        endereco: '',
        nomePai: '',
        telefonePai: '',
        nomeMae: '',
        telefoneMae: '',
    });

    const [dadosResponsavelFinanceiro, setDadosResponsavelFinanceiro] = useState({
        nomeResponsavel: '',
        cpfResponsavel: '',
        emailResponsavel: '',
        parentescoResponsavel: '',
        profisaoResponsavel: '',
        telefoneResponsavel: '',
        enderecoCompleto: '',
    });

    const [comoChegou, setComoChegou] = useState({
        google: false,
        instagram: false,
        facebook: false,
        tiktok: false,
        indicacao: '',
    });

    // Restante dos campos do formulário...
    const [esportesSelecionados, setEsportesSelecionados] = useState([]); // Array para armazenar os esportes selecionados

    // Função para lidar com a seleção de esportes
    const handleEsporteChange = (esporte) => {
        // Verifica se o esporte já está selecionado
        const isSelecionado = esportesSelecionados.includes(esporte);

        if (isSelecionado) {
            // Se estiver selecionado, remove-o da lista
            const novosEsportesSelecionados = esportesSelecionados.filter(item => item !== esporte);
            setEsportesSelecionados(novosEsportesSelecionados);
        } else {
            // Se não estiver selecionado, adiciona-o à lista
            setEsportesSelecionados([...esportesSelecionados, esporte]);
        }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        console.log('Esportes Selecionados:', esportesSelecionados);
        const matriculaEsporteData = {
            qualEsporte: esportesSelecionados.join(', '),
            vencimento,
            // os items a seguir são objetos
            dadosAluno,
            dadosResponsavelFinanceiro,
            comoChegou
        };

        try {
            setIsLoading(true);
            await dispatch(matricularAlunoEsporte(matriculaEsporteData)); // Use a ação correta para matrícula esportiva
            setIsLoading(false);
        } catch (error) {
            console.log('Error:', error?.response || error);
            setIsLoading(false);
        }
    };

    //pegando o ano mais 4 meses
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 5);

    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Lembre-se que os meses em JavaScript são baseados em zero, então adicionamos 1
    const day = currentDate.getDate();

    return (
        <div style={{ padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Helmet>
                <title>Ficha Esportes</title>
            </Helmet>
            {isLoading ? 'Loading...' :
                <form className="form-container" onSubmit={handleFormSubmit}>
                    <div>
                        <img src={escola} style={{ width: "90px" }} alt="Escola Logo" />
                    </div>
                    <h1 style={{ font: "bold", color: "red", margin: "40px" }}> {`Ficha de Matrícula Esportes`} </h1>
                    <h1 style={{ font: "bold", color: "red", marginTop: "-30px" }}>🩰🏊🥋</h1>
                    {/* Campos do formulário... */}
                    <div className='form-opinion' style={{ marginBottom: '20px', width: '85%', background: 'white', boxShadow: '0 0 4px #111' }}>
                        <ul>
                            <li style={{ alignItems: 'start' }}>
                                <label><p style={{ fontWeight: 'bold', fontSize: '14pt', fontFamily: 'arial' }}>Qual esporte vai participar?</p></label>
                                <label style={{ marginLeft: '-10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>
                                    <label htmlFor="natacao" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            name="natacao"
                                            id="natacao"
                                            checked={esportesSelecionados.includes('natacao')}
                                            onChange={() => handleEsporteChange('natacao')}
                                            style={{ width: '20px' }}
                                        />
                                        Natação
                                    </label>
                                    <label htmlFor="balé" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            name="balé"
                                            id="balé"
                                            checked={esportesSelecionados.includes('bale')}
                                            onChange={() => handleEsporteChange('bale')}
                                            style={{ width: '20px' }}
                                        />
                                        Balé
                                    </label>
                                    <label htmlFor="karate" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            name="karate"
                                            id="karate"
                                            checked={esportesSelecionados.includes('karate')}
                                            onChange={() => handleEsporteChange('karate')}
                                            style={{ width: '20px' }}
                                        />
                                        Karatê
                                    </label>
                                </label>
                            </li>
                            <li style={{ alignItems: 'start' }}>
                                <label htmlFor={`vencimento`} style={{ fontWeight: 'bold', fontSize: '13pt', fontFamily: 'arial' }}>Vencimento</label>
                                <select
                                    name={`vencimento`}
                                    id={`vencimento`}
                                    value={vencimento}
                                    onChange={(e) => setVencimento(e.target.value)}
                                    required
                                    style={{ width: "75%", height: "40px", border: "0.5px solid black" }}
                                >
                                    <option value="">Selecione o seu Vencimento</option>
                                    <option value="Dia 5">Dia 5</option>
                                    <option value="Dia 10">Dia 10</option>
                                    <option value="Dia 15">Dia 15</option>
                                </select>
                            </li>
                        </ul>
                    </div>
                    <div className='dados-alunos-responsaves'>
                        {/* Seção: Dados do Aluno */}
                        <div className="alunos">
                            <ul >
                                <h2 style={{ font: "bold 15pt arial", color: 'gray' }}>Dados do Aluno</h2>
                                {/* Seção: Dados do Aluno */}
                                <li style={{ alignItems: 'start' }}>
                                    <label> Nome do Aluno: </label>

                                    <input
                                        type="text"
                                        value={dadosAluno.nomeAluno}
                                        onChange={(e) => setDadosAluno({ ...dadosAluno, nomeAluno: e.target.value })}
                                        required
                                    />

                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label>CPF do Aluno:</label>

                                    <input
                                        type="text"
                                        value={dadosAluno.CPF}
                                        onChange={(e) => setDadosAluno({ ...dadosAluno, CPF: e.target.value })}
                                        required
                                    />

                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label>Data de Nascimento:</label>
                                    <input
                                        type="date"
                                        value={dadosAluno.dataNascimento}
                                        onChange={(e) => setDadosAluno({ ...dadosAluno, dataNascimento: e.target.value })}
                                        required
                                    />

                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label htmlFor="sangue">Tipo sanguíneo do Aluno</label>
                                    <select
                                        name="sangue"
                                        id="sangue"
                                        value={dadosAluno.tiposanguinio}
                                        onChange={(e) => setDadosAluno({ ...dadosAluno, tiposanguinio: e.target.value })}
                                        required
                                    >
                                        <option value="">Selecione o tipo sanguíneo</option>
                                        <option value="A+">A+</option>
                                        <option value="A-">A-</option>
                                        <option value="B+">B+</option>
                                        <option value="B-">B-</option>
                                        <option value="AB+">AB+</option>
                                        <option value="AB-">AB-</option>
                                        <option value="O+">O+</option>
                                        <option value="O-">O-</option>
                                    </select>
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label>Plano de Saúde:</label>
                                    <input
                                        type="text"
                                        value={dadosAluno.planoDeSaude}
                                        onChange={(e) => setDadosAluno({ ...dadosAluno, planoDeSaude: e.target.value })}
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label>Com Quem Mora:</label>
                                    <input
                                        type="text"
                                        value={dadosAluno.comQuemMora}
                                        onChange={(e) => setDadosAluno({ ...dadosAluno, comQuemMora: e.target.value })}
                                    />

                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label>Endereço:</label>
                                    <input
                                        type="text"
                                        value={dadosAluno.endereco}
                                        onChange={(e) => setDadosAluno({ ...dadosAluno, endereco: e.target.value })}
                                    />

                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label>Nome do Pai:</label>
                                    <input
                                        type="text"
                                        value={dadosAluno.nomePai}
                                        onChange={(e) => setDadosAluno({ ...dadosAluno, nomePai: e.target.value })}
                                    />

                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label>Telefone do Pai:</label>
                                    <input
                                        type="text"
                                        value={dadosAluno.telefonePai}
                                        onChange={(e) => setDadosAluno({ ...dadosAluno, telefonePai: e.target.value })}
                                    />

                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label>Nome da Mãe:</label>
                                    <input
                                        type="text"
                                        value={dadosAluno.nomeMae}
                                        onChange={(e) => setDadosAluno({ ...dadosAluno, nomeMae: e.target.value })}
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label>Telefone da Mãe:</label>
                                    <input
                                        type="text"
                                        value={dadosAluno.telefoneMae}
                                        onChange={(e) => setDadosAluno({ ...dadosAluno, telefoneMae: e.target.value })}
                                    />

                                </li>
                            </ul>
                            {/* Adicione mais campos relevantes para os dados do aluno aqui */}

                            {/* Adicione mais campos relevantes para os dados do aluno aqui */}
                        </div>

                        {/* Seção: Dados do Responsável Financeiro */}
                        <div className="responsaveis">
                            <ul >
                                <h2 style={{ font: "bold 15pt arial", color: "gray" }}>Dados do Responsável Financeiro</h2>
                                <li style={{ alignItems: 'start' }}>


                                    <label>Nome do Responsável:</label>
                                    <input
                                        type="text"
                                        value={dadosResponsavelFinanceiro.nomeResponsavel}
                                        onChange={(e) => setDadosResponsavelFinanceiro({ ...dadosResponsavelFinanceiro, nomeResponsavel: e.target.value })}
                                        required
                                    />

                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label>
                                        CPF do Responsável:
                                    </label>
                                    <input
                                        type="text"
                                        value={dadosResponsavelFinanceiro.cpfResponsavel}
                                        onChange={(e) => setDadosResponsavelFinanceiro({ ...dadosResponsavelFinanceiro, cpfResponsavel: e.target.value })}
                                        required
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label>
                                        Email do Responsável:
                                    </label>
                                    <input
                                        type="email"
                                        value={dadosResponsavelFinanceiro.emailResponsavel}
                                        onChange={(e) => setDadosResponsavelFinanceiro({ ...dadosResponsavelFinanceiro, emailResponsavel: e.target.value })}
                                        required
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label htmlFor={`parentescoResponsavel`}>Parentesco</label>
                                    <select
                                        name={'parentescoResponsavel'}
                                        id={'parentescoResponsavel'}
                                        value={dadosResponsavelFinanceiro.parentescoResponsavel}
                                        onChange={(e) => setDadosResponsavelFinanceiro({ ...dadosResponsavelFinanceiro, parentescoResponsavel: e.target.value })}
                                        required
                                    >
                                        <option value="">Selecione um parentesco</option>
                                        <option value="pai">Pai</option>
                                        <option value="mãe">Mãe</option>
                                        <option value="avô">Avô</option>
                                        <option value="avó">Avó</option>
                                    </select>
                                </li>
                                <li style={{ alignItems: 'start' }}>

                                    <label>
                                        Profissão do Responsável:
                                    </label>
                                    <input
                                        type="text"
                                        value={dadosResponsavelFinanceiro.profisaoResponsavel}
                                        onChange={(e) => setDadosResponsavelFinanceiro({ ...dadosResponsavelFinanceiro, profisaoResponsavel: e.target.value })}
                                        required
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label>
                                        Telefone do Responsável:
                                    </label>
                                    <input
                                        type="text"
                                        value={dadosResponsavelFinanceiro.telefoneResponsavel}
                                        onChange={(e) => setDadosResponsavelFinanceiro({ ...dadosResponsavelFinanceiro, telefoneResponsavel: e.target.value })}
                                        required
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label>
                                        Endereço Completo do Responsável:
                                    </label>
                                    <input
                                        type="text"
                                        value={dadosResponsavelFinanceiro.enderecoCompleto}
                                        onChange={(e) => setDadosResponsavelFinanceiro({ ...dadosResponsavelFinanceiro, enderecoCompleto: e.target.value })}
                                        required
                                    />

                                </li>
                            </ul>
                            {/* Adicione mais campos relevantes para os dados do responsável financeiro aqui */}
                        </div>
                    </div>
                    <div className='form-opinion' style={{ marginTop: "20px", width: "90%" }}>
                        <ul>
                            <li style={{ alignItems: 'start' }}>
                                <h5>Como chegou ao colégio?</h5>
                                <label htmlFor="google">
                                    <input
                                        type="checkbox"
                                        name="google"
                                        id="google"
                                        checked={comoChegou.google}
                                        onChange={(e) => setComoChegou({ ...comoChegou, google: e.target.checked })}
                                    />
                                    Google
                                </label>
                                <label htmlFor="instagram">
                                    <input
                                        type="checkbox"
                                        name="instagram"
                                        id="instagram"
                                        checked={comoChegou.instagram}
                                        onChange={(e) => setComoChegou({ ...comoChegou, instagram: e.target.checked })}
                                    />
                                    Instagram
                                </label>
                                <label htmlFor="facebook">
                                    <input
                                        type="checkbox"
                                        name="facebook"
                                        id="facebook"
                                        checked={comoChegou.facebook}
                                        onChange={(e) => setComoChegou({ ...comoChegou, facebook: e.target.checked })}
                                    />
                                    Facebook
                                </label>
                                <label htmlFor="tiktok">
                                    <input
                                        type="checkbox"
                                        name="tiktok"
                                        id="tiktok"
                                        checked={comoChegou.tiktok}
                                        onChange={(e) => setComoChegou({ ...comoChegou, tiktok: e.target.checked })}
                                    />
                                    Tiktok
                                </label>
                                <label htmlFor="indicacao">
                                    <input
                                        type="checkbox"
                                        name="indicacao"
                                        id="indicacao"
                                        checked={comoChegou.indicacao !== ''}
                                        onChange={(e) => setComoChegou({ ...comoChegou, indicacao: e.target.checked ? 'Preencher abaixo' : '' })}
                                    />
                                    Indicação:
                                </label>
                                {comoChegou.indicacao !== '' && (
                                    <div>
                                        <h7>Quem indicou ?</h7>
                                        <input
                                            type="text"
                                            name="indicacaoOption"
                                            value={comoChegou.indicacao === 'Preencher abaixo' ? '' : comoChegou.indicacao}
                                            onChange={(e) => setComoChegou({ ...comoChegou, indicacao: e.target.value })}
                                        />
                                    </div>
                                )}
                            </li>
                        </ul>
                    </div>
                    {isUserSportLoggedIn ? <>Recarregue a pagina</> : <button type="submit" className="button primary" style={{ background: 'red', width: '100px', alignSelf: 'center', marginTop: '20px' }} disabled={isLoading}>{isLoading ? 'Loading...' : 'Matricular'}</button>}
                </form >
            }
        </div >
    )
}

export default FormEsportes