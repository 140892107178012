import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import Cookies from 'js-cookie';
import MatriculaReducer from './reducers/MatriculaReducer';
import MatriculaEsportesReducer from './reducers/MatriculaEsportesReducer';
import CampanhaDeMatriculasReducer from './reducers/CampanhaDeMatriculasReducer';
import InscricaoColoniaReducer from './reducers/InscricaoColoniaReducer';
import catequeseReducer from './reducers/MatriculaCatequeseReducer';

const store = configureStore({
  reducer: {
    matricula: MatriculaReducer,
    matriculaEsporte: MatriculaEsportesReducer,
    campanhaDeMatriculas: CampanhaDeMatriculasReducer,
    InscricaoColonia: InscricaoColoniaReducer,
    inscricaoCatequese: catequeseReducer
  },
  middleware: [thunk],
});

// Carregar o estado inicial do usuário a partir do cookie
const userInfoFromCookie = Cookies.get('userInfo');
if (userInfoFromCookie) {
  console.log('User info from cookie:', JSON.parse(userInfoFromCookie));
  store.dispatch({ type: 'USER_SIGNIN_SUCCESS', payload: JSON.parse(userInfoFromCookie) });
}

const userInfoSportFromCookie = Cookies.get('userInfoSport');
if (userInfoSportFromCookie) {
  console.log('User info from cookie:', JSON.parse(userInfoSportFromCookie));
  store.dispatch({ type: 'MATRICULA_ESPORTE_SUCCESS', payload: JSON.parse(userInfoSportFromCookie) });
}

const campanhaDeMatriculasDataFromCookie = Cookies.get('campanhaDeMatriculasData');
if (campanhaDeMatriculasDataFromCookie) {
  console.log('Campanha de matrículas data from cookie:', JSON.parse(campanhaDeMatriculasDataFromCookie));
  store.dispatch({ type: 'LOAD_CAMPANHA_MATRICULAS_FROM_COOKIE', payload: JSON.parse(campanhaDeMatriculasDataFromCookie) });
}

const inscricaoColoniaFromCookie = Cookies.get('InscricaoColoniaData');
if (inscricaoColoniaFromCookie) {
  console.log('Campanha de matrículas data from cookie:', JSON.parse(inscricaoColoniaFromCookie));
  store.dispatch({ type: 'CRIAR_INSCRICAO_SUCESSO', payload: JSON.parse(inscricaoColoniaFromCookie) });
}

const inscricaoCatequeseFromCookie = Cookies.get('InscricaoCatequeseData');
if (inscricaoCatequeseFromCookie) {
  console.log('Inscrição catequese data from cookie:', JSON.parse(inscricaoCatequeseFromCookie));
  store.dispatch({ type: 'CRIAR_INSCRICAO_CATEQUESE_SUCESSO', payload: JSON.parse(inscricaoCatequeseFromCookie) });
}

export default store;