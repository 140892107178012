const initialState = {
    inscricaoCatequese: null,
    loading: false,
    error: null,
};

const catequeseReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CRIAR_INSCRICAO_CATEQUESE_INICIO':
            return {
                ...state,
                loading: true,
                error: null,
            };

        case 'CRIAR_INSCRICAO_CATEQUESE_SUCESSO':
            return {
                ...state,
                loading: false,
                inscricaoCatequese: action.payload,
            };

        case 'CRIAR_INSCRICAO_CATEQUESE_ERRO':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default catequeseReducer;
