// screens/BeforeForm.js
import React from 'react';
import { Link } from 'react-router-dom';
import escola from '../assets/OIP.png';
import whatssap from '../assets/whatsapp.png';
import youtube from '../assets/youtube.png';
import site from '../assets/internet.png';
import tiktok from '../assets/tiktok.png';
import instagram from '../assets/instagram.png'
import pix from '../assets/pix.png'

const BeforeFormCatequese = () => {
    return (
        <div className='Before-form'>
            <div className='Before-form-component'>
                <div className="grid-container">
                    <div className="grid-item">
                        <img src={escola} style={{ width: "80px" }} alt="Escola Logo" />
                    </div>
                    <div className="grid-item">
                        <p className='B-F-G' style={{ color: "gray" }}>Sua ficha da catequese foi enviada com sucesso. ✅😊!</p>
                        <p>Há 27 anos,construindo laços e saberes.</p>
                        <p>Visite nossas redes sociais.</p>
                    </div>
                    <div className="grid-item">
                        <div className='nav-links'>
                            <Link className='nav-link' to="https://colegioalternativocolaco.com.br/"><img src={site} style={{ width: "38px" }} alt="Escola Logo" /></Link>
                            <Link className='nav-link' to="https://api.whatsapp.com/send?phone=5585988068633"><img src={whatssap} style={{ width: "40px" }} alt="Escola Logo" /></Link>
                            <Link className='nav-link' to="https://www.youtube.com/@colegioalternativocolaco"><img src={youtube} style={{ width: "40px" }} alt="Escola Logo" /></Link>
                            <Link className='nav-link' to="https://www.instagram.com/colegioalternativocolaco"><img src={instagram} style={{ width: "40px" }} alt="Escola Logo" /></Link>
                            <Link className='nav-link' to="https://www.tiktok.com/@colegioalternativocolaco?_t=8XxozM2TnMr&_r=1"><img src={tiktok} style={{ width: "40px" }} alt="Escola Logo" /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BeforeFormCatequese;
