const initialState = {
    loading: false,
    error: null,
    campanhaDeMatriculasData: [], // Ou qualquer estrutura que desejar
};

const CampanhaDeMatriculasReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CRIAR_CAMPANHA_REQUEST':
            return {
                ...state,
                loading: true,
            };
        case 'CRIAR_CAMPANHA_SUCCESS':
            return {
                ...state,
                loading: false,
                error: null,
                campanhaDeMatriculasData: action.payload,
            };
        case 'CRIAR_CAMPANHA_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case 'LOAD_CAMPANHA_MATRICULAS_FROM_COOKIE':
            return {
                ...state,
                campanhaDeMatriculasData: action.payload,
            };
        default:
            return state;
    }
};

export default CampanhaDeMatriculasReducer;
