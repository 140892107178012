import React, { useState } from 'react';
import apiAxios from '../api/apiAxios';
import { Helmet } from 'react-helmet';
import escola from '../assets/OIP.png';
import { useDispatch } from 'react-redux';
import { criarInscricaoCatequese } from '../actions/MatriculaCatequeseAction';
import ReactLoading from 'react-loading';

const FormCatequese = () => {

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        nome: '',
        sexo: '',
        nomePai: '',
        nomeMae: '',
        dataNascimento: '',
        Naturalidade: '',
        Nacionalidade: '',
        Endereço: '',
        Numero: '',
        Completo: '',
        Bairro: '',
        CEP: '',
        email: '',
        whatsapp: '',
        fone: '',
        religiaoPai: '',
        religiaoMae: '',
        batizado: false,
        dataBatismo: null,
        paroquiaBatismo: '',
        paroquiaQuePertence: '',
        participaDasMissas: false,
        participaMovimentosPastorais: false,
        justificativaDeParticipacao: '',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setIsLoading(true);
            // Despache a nova action para enviar o formulário de catequese
            const response = await dispatch(criarInscricaoCatequese(formData));
            setIsLoading(false);
            // Lógica adicional após a inscrição bem-sucedida, se necessário
        } catch (error) {
            console.error('Erro ao enviar inscrição de catequese:', error);

            // Lógica adicional para lidar com erros, se necessário
        }
    };

    // Defina um array de objetos para representar os campos
    const formFields = [
        { label: 'Nome', type: 'text', name: 'nome' },
        { label: 'Sexo', type: 'select', name: 'sexo', options: ['Masculino', 'Feminino'] },
        { label: 'Nome do Pai', type: 'text', name: 'nomePai' },
        { label: 'Nome da Mãe', type: 'text', name: 'nomeMae' },
        { label: 'Data de Nascimento', type: 'date', name: 'dataNascimento' },
        { label: 'Naturalidade', type: 'text', name: 'Naturalidade' },
        { label: 'Nacionalidade', type: 'text', name: 'Nacionalidade' },
        { label: 'Endereço', type: 'text', name: 'Endereço' },
        { label: 'Número', type: 'text', name: 'Numero' },
        { label: 'Completo', type: 'text', name: 'Completo' },
        { label: 'Bairro', type: 'text', name: 'Bairro' },
        { label: 'CEP', type: 'text', name: 'CEP' },
        { label: 'Email', type: 'email', name: 'email' },
        { label: 'Whatsapp', type: 'text', name: 'whatsapp' },
        { label: 'Telefone', type: 'text', name: 'fone' },
        { label: 'Religião do Pai', type: 'text', name: 'religiaoPai' },
        { label: 'Religião da Mãe', type: 'text', name: 'religiaoMae' },
        { label: 'Batizado', type: 'checkbox', name: 'batizado' },
        { label: 'Data do Batismo', type: 'date', name: 'dataBatismo' },
        { label: 'Paróquia do Batismo', type: 'text', name: 'paroquiaBatismo' },
        { label: 'Paróquia que Pertence', type: 'text', name: 'paroquiaQuePertence' },
        { label: 'Participa das Missas', type: 'checkbox', name: 'participaDasMissas' },
        { label: 'Participa de Movimentos Pastorais', type: 'checkbox', name: 'participaMovimentosPastorais' },
        { label: 'Justificativa de Participação', type: 'textarea', name: 'justificativaDeParticipacao' },
    ];

    return (
        <div style={{ padding: "20px", display: "flex", justifyContent: "center", alignItems: 'center' }}>
            <Helmet>
                <title>Ficha de matrícula catequese</title>
            </Helmet>
            {isLoading ? <div style={{ height: "100vh", display: 'flex', alignItems: "center", justifyContent: "center" }}><ReactLoading type="spin" color="#FF0000" height={50} width={50} /></div> :
                <form className="form-container-campanha" onSubmit={handleSubmit}>
                    <div>
                        <img src={escola} style={{ width: "90px" }} alt="Escola Logo" />
                    </div>
                    <h5 style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", color: "red" }}>
                        <p style={{ color: "red" }}>INSCRIÇÃO CATEQUESE</p>
                        <>🙏🕊️⛪✝️</>
                    </h5>
                    {formFields.map((field, index) => (
                        <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '10px' }}>
                            <label >
                                {field.label}:
                                {field.type === 'checkbox' ? (
                                    <input
                                        type={field.type}
                                        name={field.name}
                                        checked={formData[field.name]}
                                        onChange={handleChange}
                                        style={{ alignSelf: 'flex-start', marginRight: '5px' }}
                                    />
                                ) : field.type === 'select' ? (
                                    <select
                                        name={field.name}
                                        value={formData[field.name]}
                                        onChange={handleChange}
                                    >
                                        <option value="">Selecione</option>
                                        {field.options.map(option => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                ) : (
                                    <input
                                        type={field.type}
                                        name={field.name}
                                        value={formData[field.name]}
                                        onChange={handleChange}
                                    />
                                )}
                            </label>
                            <br />
                        </div>
                    ))}

                    <button className="button primary" style={{ background: 'red', width: '100px', alignSelf: "center", marginTop: "20px" }} type="submit">Enviar Inscrição</button>
                </form>
            }
        </div>
    );
};

export default FormCatequese;
