const initialState = {
  inscricao: null, // Ou null ou um objeto vazio, dependendo do seu estado inicial
  loading: false,
  error: null,
};

// Reducer para gerenciar as ações relacionadas a inscrições na colônia de férias
const InscricaoColoniaReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CRIAR_INSCRICAO_INICIO':
      return {
        ...state,
        loading: true,
        error: null,
      };

    case 'CRIAR_INSCRICAO_SUCESSO':
      return {
        ...state,
        loading: false,
        inscricao: action.payload, // Armazenar os dados da inscrição criada
      };

    case 'CRIAR_INSCRICAO_ERRO':
      return {
        ...state,
        loading: false,
        error: action.payload, // Armazenar a mensagem de erro
      };

    default:
      return state;
  }
};

export default InscricaoColoniaReducer;
