const initialState = {
    matriculaEsporteData: null, // Dados de matrícula em esportes
    error: null, // Mensagens de erro
};

const MatriculaEsportesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'MATRICULA_ESPORTE_SUCCESS':
            return {
                ...state,
                matriculaEsporteData: action.payload,
                error: null, // Limpa qualquer erro anterior
            };
        case 'MATRICULA_ESPORTE_ERROR':
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default MatriculaEsportesReducer;
