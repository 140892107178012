import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { matricularAluno } from '../actions/MatriculaAction';
import escola from '../assets/OIP.png';
import { formatCPF, formatTelefone } from '../components/Utils';
import { Helmet } from 'react-helmet';
import ReactLoading from 'react-loading';

const Form = () => {

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    // Create individual states for form fields
    const [nomeAluno, setNomeAluno] = useState('');
    const [CPF, setCPF] = useState('');
    const [dataNascimento, setDataNascimento] = useState(''); // State for dataNascimento
    const [serie, setSerie] = useState('');
    const [tiposanguinio, setTipoSanguinio] = useState('');
    const [emergencia, setEmergencia] = useState('');
    const [endereco, setEndereco] = useState('');
    const [bairro, setBairro] = useState('');
    const [CEP, setCEP] = useState('');
    const [overView, setOverView] = useState('');

    //Dados irmão 
    const [temIrmao, setTemIrmao] = useState(false);
    const [nomeAluno2, setNomeAluno2] = useState('');
    const [CPF2, setCPF2] = useState('');
    const [dataNascimento2, setDataNascimento2] = useState(''); // State for dataNascimento
    const [serie2, setSerie2] = useState('');
    const [tiposanguinio2, setTipoSanguinio2] = useState('');
    const [emergencia2, setEmergencia2] = useState('');
    const [bairro2, setBairro2] = useState('');
    const [CEP2, setCEP2] = useState('');
    const [endereco2, setEndereco2] = useState('');
    const [overView2, setOverView2] = useState('');
    const [vencimento2, setVencimento2] = useState('');

    // Estados para os responsáveis
    const [nomeResponsavel, setNomeResponsavel] = useState('');
    const [cpfResponsavel, setCpfResponsavel] = useState('');
    const [emailResponsavel, setEmailResponsavel] = useState('');
    const [parentescoResponsavel, setParentescoResponsavel] = useState('');
    const [profisaoResponsavel, setprofisaoResponsavel] = useState('');
    const [telefoneResponsavel, setTelefoneResponsavel] = useState('');
    const [vencimento, setVencimento] = useState('');
    const [responsavelPrincipal, setResponsavelPrincipal] = useState('');
    const [comoChegou, setComoChegou] = useState({
        google: false,
        instagram: false,
        facebook: false,
        tiktok: false,
        indicacao: '',
    });
    const [responsavel1, setResponsavel1] = useState({
        nomeResponsavel: '',
        cpfResponsavel: '',
        emailResponsavel: '',
        parentescoResponsavel: '',
        profisaoResponsavel: '',
        telefoneResponsavel: '',
        responsavelPrincipal: 'Não'
    });
    const [responsavel2, setResponsavel2] = useState({
        nomeResponsavel: '',
        cpfResponsavel: '',
        emailResponsavel: '',
        parentescoResponsavel: '',
        profissaoResponsavel: '',
        telefoneResponsavel: '',
        responsavelPrincipal: 'Não'
    });
    // Above the return statement
    const [showResponsavel2, setShowResponsavel2] = useState(false);

    const [selectedShift, setSelectedShift] = useState("");
    const [selectedShift2, setSelectedShift2] = useState("");

    const handleToggleResponsavel2 = () => {
        setShowResponsavel2(!showResponsavel2);
    };

    // Resto do seu código de envio do formulário


    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const isComoChegouSelected =
            comoChegou.google ||
            comoChegou.instagram ||
            comoChegou.facebook ||
            comoChegou.tiktok ||
            (comoChegou.indicacao && comoChegou.indicacao !== 'Preencher abaixo');

        if (!isComoChegouSelected) {
            alert('Selecione pelo menos uma opção em "Como chegou ao colégio"');
            return;
        }

        const combinedSerie = `${serie} ${selectedShift}`;
        const combinedSerie2 = `${serie2} ${selectedShift2}`;

        console.log(responsavel1);

        const matriculaData = {
            nomeAluno,
            CPF,
            dataNascimento,
            serie: combinedSerie,
            tiposanguinio,
            emergencia,
            endereco,
            bairro,
            CEP,
            overView,
            nomeResponsavel,
            cpfResponsavel,
            emailResponsavel,
            parentescoResponsavel,
            profisaoResponsavel,
            telefoneResponsavel,
            vencimento,
            responsavelPrincipal,
            comoChegou,
            responsavel1,
            vencimento2,
            responsavel2
        };

        try {
            setIsLoading(true); // Set loading state to false after successful matriculation

            await dispatch(matricularAluno(matriculaData));

            if (temIrmao) {
                const matriculaData2 = {
                    nomeAluno: nomeAluno2,
                    CPF: CPF2,
                    dataNascimento: dataNascimento2,
                    serie: combinedSerie2,
                    tiposanguinio: tiposanguinio2,
                    emergencia: emergencia2,
                    endereco: endereco2,
                    bairro: bairro2,
                    CEP: CEP2,
                    overView: overView2,
                    nomeResponsavel,
                    cpfResponsavel,
                    emailResponsavel,
                    parentescoResponsavel,
                    profisaoResponsavel,
                    telefoneResponsavel,
                    comoChegou,
                    responsavel1,
                    responsavel2
                };

                console.log('Matricula Data 2:', matriculaData2);

                // Dispatch action for the second sibling
                await dispatch(matricularAluno(matriculaData2));
            }

            setIsLoading(false); // Set loading state to false after successful matriculation

        } catch (error) {
            console.log('Error:', error?.response || error);
            setIsLoading(false); // Set loading state to false after successful matriculation
        }
    };

    const handleDataNascimentoChange = (e) => {
        const input = e.target.value;
        // Remove qualquer caractere não numérico
        const numericValue = input.replace(/\D/g, '');

        // Separe os números em dia, mês e ano
        const day = numericValue.slice(0, 2);
        const month = numericValue.slice(2, 4);
        const year = numericValue.slice(4, 8);

        // Formate no estilo "dd/mm/yyyy"
        let formattedValue = '';
        if (day) {
            formattedValue += day;
            if (month) {
                formattedValue += `/${month}`;
                if (year) {
                    formattedValue += `/${year}`;
                }
            }
        }

        // Atualize o estado com o valor formatado
        setDataNascimento(formattedValue);
    };

    const handleDataNascimentoChange2 = (e) => {
        const input = e.target.value;
        // Remove qualquer caractere não numérico
        const numericValue = input.replace(/\D/g, '');

        // Separe os números em dia, mês e ano
        const day = numericValue.slice(0, 2);
        const month = numericValue.slice(2, 4);
        const year = numericValue.slice(4, 8);

        // Formate no estilo "dd/mm/yyyy"
        let formattedValue = '';
        if (day) {
            formattedValue += day;
            if (month) {
                formattedValue += `/${month}`;
                if (year) {
                    formattedValue += `/${year}`;
                }
            }
        }

        // Atualize o estado com o valor formatado
        setDataNascimento2(formattedValue);
    };

    //funções CPF
    const formattedCPF = formatCPF(CPF);
    const formattedCPF2 = formatCPF(CPF2);
    const formattedCPFResponsavel = formatCPF(cpfResponsavel);
    const formattedCPFResponsavel1 = formatCPF(responsavel1.cpfResponsavel);
    const formattedCPFResponsavel2 = formatCPF(responsavel2.cpfResponsavel);

    //funções Telefone
    const formattedTelResponsavel = formatTelefone(telefoneResponsavel);
    const formattedTelResponsavel1 = formatTelefone(responsavel1.telefoneResponsavel);
    const formattedTelResponsavel2 = formatTelefone(responsavel2.telefoneResponsavel);

    //pegando o ano mais 4 meses
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 5);

    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Lembre-se que os meses em JavaScript são baseados em zero, então adicionamos 1
    const day = currentDate.getDate();

    const formatDataBrasil = (data) => {
        const [year, month, day] = data.split('-');
        return `${day}/${month}/${year}`;
    };


    return (
        <div style={{ padding: "20px", display: "flex", justifyContent: "center", alignItems: 'center' }}>
            <Helmet>
                <title>Ficha de matrícula Alternativo</title>
            </Helmet>
            {isLoading ? <div style={{ height: "100vh", display: 'flex', alignItems: "center", justifyContent: "center" }}><ReactLoading type="spin" color="#FF0000" height={50} width={50} /></div> :
                <form className="form-container" onSubmit={handleFormSubmit}>
                    <div>
                        <img src={escola} style={{ width: "90px" }} alt="Escola Logo" />
                    </div>
                    <h1 style={{ font: "bold", color: "red", margin: "40px" }}> {`Ficha de Matrícula ${year}`}</h1>
                    <div className='dados-alunos-responsaves'>
                        {/* Dados Alunos */}
                        <div className='alunos'>
                            <ul style={{ padding: "" }}>
                                <h5 style={{ font: "bold 16pt arial" }}>Dados do aluno :</h5>
                                <li style={{ alignItems: 'start' }}>
                                    <label htmlFor={`nomeAluno`}>Nome do aluno</label>
                                    <input
                                        type="text"
                                        name={`nomeAluno`}
                                        id={`nomeAluno`}
                                        value={nomeAluno}
                                        maxLength={60}
                                        onChange={(e) => setNomeAluno(e.target.value)}
                                        required
                                    />
                                </li>
                                <li style={{ alignItems: "start" }}>
                                    <label htmlFor="dataNascimento">Data de nascimento</label>
                                    <input
                                        type="text"
                                        name="dataNascimento"
                                        id="dataNascimento"
                                        value={dataNascimento}
                                        onChange={handleDataNascimentoChange}
                                        placeholder="dd/mm/yyyy"
                                        maxLength="10" // Limita a entrada a 10 caracteres
                                        required
                                    />
                                </li>
                                <li style={{ alignItems: "start" }}>
                                    <label htmlFor="cpfAluno">CPF do Aluno</label>
                                    <input
                                        type="text"
                                        name="cpfAluno"
                                        id="cpfAluno"
                                        value={formattedCPF}
                                        maxLength={11}
                                        onChange={(e) => setCPF(e.target.value)}
                                        required
                                    />
                                </li>
                                <li style={{ alignItems: "start" }}>
                                    <label htmlFor="serie">Série do aluno</label>
                                    <select
                                        name="serie"
                                        id="serie"
                                        value={serie}
                                        onChange={(e) => setSerie(e.target.value)}
                                        required
                                    >
                                        <option value="">Selecione a série do aluno</option>
                                        <option value="Infantil 1">Infantil 1</option>
                                        <option value="Infantil 2">Infantil 2</option>
                                        <option value="Infantil 3">Infantil 3</option>
                                        <option value="Infantil 4">Infantil 4</option>
                                        <option value="Infantil 5">Infantil 5</option>
                                        <option value="1º Ano Fundamental">1º Ano Fundamental</option>
                                        <option value="2º Ano Fundamental">2º Ano Fundamental</option>
                                        <option value="3º Ano Fundamental">3º Ano Fundamental</option>
                                        <option value="4º Ano Fundamental">4º Ano Fundamental</option>
                                        <option value="5º Ano Fundamental">5º Ano Fundamental</option>
                                        {/* Adicione mais opções conforme necessário */}
                                    </select>
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label><p style={{ font: "bold 12pt arial" }}>Turno</p></label>
                                    <label style={{ marginLeft: "-10px", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>
                                        <label htmlFor="manha" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <input
                                                type="checkbox"
                                                name="manha"
                                                id="manha"
                                                checked={selectedShift === 'manhã'}
                                                onChange={() => setSelectedShift('manhã')}
                                                style={{ width: "20px" }}
                                            />
                                            Manhã
                                        </label>
                                        <label htmlFor="tarde" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <input
                                                type="checkbox"
                                                name="tarde"
                                                id="tarde"
                                                checked={selectedShift === 'tarde'}
                                                onChange={() => setSelectedShift('tarde')}
                                                style={{ width: "20px" }}
                                            />
                                            Tarde
                                        </label>
                                        <label htmlFor="integral" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <input
                                                type="checkbox"
                                                name="integral"
                                                id="integral"
                                                checked={selectedShift === 'integral'}
                                                onChange={() => setSelectedShift('integral')}
                                                style={{ width: "20px" }}
                                            />
                                            Integral
                                        </label>
                                        <label htmlFor="semintegralmanha" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <input
                                                type="checkbox"
                                                name="semintegralmanha"
                                                id="semintegralmanha"
                                                checked={selectedShift === 'semi-integral manhã'}
                                                onChange={() => setSelectedShift('semi-integral manhã')}
                                                style={{ width: "20px" }}
                                            />
                                            Semi-integral manhã
                                        </label>
                                        <label htmlFor="semintegraltarde" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <input
                                                type="checkbox"
                                                name="semintegraltarde"
                                                id="semintegraltarde"
                                                checked={selectedShift === 'semi-integral tarde'}
                                                onChange={() => setSelectedShift('semi-integral tarde')}
                                                style={{ width: "20px" }}
                                            />
                                            Semi-integral Tarde
                                        </label>
                                    </label>
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label htmlFor="sangue">Tipo sanguíneo do Aluno</label>
                                    <select
                                        name="sangue"
                                        id="sangue"
                                        value={tiposanguinio}
                                        onChange={(e) => setTipoSanguinio(e.target.value)}
                                        required
                                    >
                                        <option value="">Selecione o tipo sanguíneo</option>
                                        <option value="A+">A+</option>
                                        <option value="A-">A-</option>
                                        <option value="B+">B+</option>
                                        <option value="B-">B-</option>
                                        <option value="AB+">AB+</option>
                                        <option value="AB-">AB-</option>
                                        <option value="O+">O+</option>
                                        <option value="O-">O-</option>
                                        <option value="Não sei informar o tipo sanguíneo no momento, mas me comprometo a informar a Escola assim que possível.">Não sei informar o tipo sanguíneo no momento, mas me comprometo a informar a Escola assim que possível.</option>
                                    </select>
                                </li>
                                <li style={{ alignItems: "start" }}>
                                    <label htmlFor="medicamento">Medicamento de Emergência</label>
                                    <input
                                        type="text"
                                        name="medicamento"
                                        id="medicamento"
                                        value={emergencia}
                                        onChange={(e) => setEmergencia(e.target.value)}
                                        required
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label>Bairro:</label>
                                    <input
                                        type="text"
                                        value={bairro}
                                        onChange={(e) => setBairro(e.target.value)}
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label>Endereço completo:</label>
                                    <input
                                        type="text"
                                        value={endereco}
                                        onChange={(e) => setEndereco(e.target.value)}
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label>CEP:</label>
                                    <input
                                        type="text"
                                        value={CEP}
                                        onChange={(e) => setCEP(e.target.value)}
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label htmlFor={`vencimento`} style={{ fontWeight: 'bold', fontSize: '13pt', fontFamily: 'arial' }}>Vencimento</label>
                                    <select
                                        name={`vencimento`}
                                        id={`vencimento`}
                                        value={vencimento}
                                        onChange={(e) => setVencimento(e.target.value)}
                                        required
                                        style={{ width: "75%" }}
                                    >
                                        <option value="">Selecione o seu Vencimento</option>
                                        <option value="Dia 5">Dia 5</option>
                                        <option value="Dia 10">Dia 10</option>
                                        <option value="Dia 15">Dia 15</option>
                                    </select>
                                </li>
                                <li style={{ alignItems: "start" }}>
                                    <label htmlFor="overview">Informações Gerais do Aluno(opcional):</label>
                                    <textarea className="textarea"
                                        name="overview"
                                        id="overview"
                                        rows="3"
                                        value={overView}
                                        onChange={(e) => setOverView(e.target.value)}
                                    />
                                </li>
                                <li style={{ display: 'flex', alignItems: 'flex-start', }}>
                                    <label style={{ font: "bold 13pt arial", color: "red" }} htmlFor="temIrmao">Matricular irmão ?</label>
                                    <input
                                        type="checkbox"
                                        name="temIrmao"
                                        id="temIrmao"
                                        checked={temIrmao}
                                        onChange={() => setTemIrmao(!temIrmao)}
                                        style={{ width: '25px', marginLeft: "15px", marginTop: "5px" }}
                                    />
                                </li>
                                {temIrmao && (
                                    <>
                                        {/* Campos para o segundo irmão */}
                                        <li style={{ alignItems: 'start' }}>
                                            <label htmlFor={`nomeAluno`}>Nome do aluno</label>
                                            <input
                                                type="text"
                                                name={`nomeAluno`}
                                                id={`nomeAluno`}
                                                value={nomeAluno2}
                                                maxLength={60}
                                                onChange={(e) => setNomeAluno2(e.target.value)}
                                                required
                                            />
                                        </li>
                                        <li style={{ alignItems: "start" }}>
                                            <label htmlFor="dataNascimento">Data de nascimento</label>
                                            <input
                                                type="text"
                                                name="dataNascimento"
                                                id="dataNascimento"
                                                value={dataNascimento2}
                                                onChange={handleDataNascimentoChange2}
                                                placeholder="dd/mm/yyyy"
                                                maxLength="10" // Limita a entrada a 10 caracteres
                                                required
                                            />
                                        </li>
                                        <li style={{ alignItems: "start" }}>
                                            <label htmlFor="cpfAluno">CPF do Aluno</label>
                                            <input
                                                type="text"
                                                name="cpfAluno"
                                                id="cpfAluno"
                                                value={formattedCPF2}
                                                maxLength={11}
                                                onChange={(e) => setCPF2(e.target.value)}
                                                required
                                            />
                                        </li>
                                        <li style={{ alignItems: "start" }}>
                                            <label htmlFor="serie">Série do aluno</label>
                                            <select
                                                name="serie"
                                                id="serie"
                                                value={serie2}
                                                onChange={(e) => setSerie2(e.target.value)}
                                                required
                                            >
                                                <option value="">Selecione a série do aluno</option>
                                                <option value="Infantil 1">Infantil 1</option>
                                                <option value="Infantil 2">Infantil 2</option>
                                                <option value="Infantil 3">Infantil 3</option>
                                                <option value="Infantil 4">Infantil 4</option>
                                                <option value="Infantil 5">Infantil 5</option>
                                                <option value="1º Ano Fundamental">1º Ano Fundamental</option>
                                                <option value="2º Ano Fundamental">2º Ano Fundamental</option>
                                                <option value="3º Ano Fundamental">3º Ano Fundamental</option>
                                                <option value="4º Ano Fundamental">4º Ano Fundamental</option>
                                                <option value="5º Ano Fundamental">5º Ano Fundamental</option>
                                                {/* Adicione mais opções conforme necessário */}
                                            </select>
                                        </li>
                                        <li style={{ alignItems: 'start' }}>
                                            <label><p style={{ font: "bold 12pt arial" }}>Turno</p></label>
                                            <label style={{ marginLeft: "-10px", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>
                                                <label htmlFor="manha" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <input
                                                        type="checkbox"
                                                        name="manha"
                                                        id="manha"
                                                        checked={selectedShift2 === 'manhã'}
                                                        onChange={() => setSelectedShift2('manhã')}
                                                        style={{ width: "20px" }}
                                                    />
                                                    Manhã
                                                </label>
                                                <label htmlFor="tarde" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <input
                                                        type="checkbox"
                                                        name="tarde"
                                                        id="tarde"
                                                        checked={selectedShift2 === 'tarde'}
                                                        onChange={() => setSelectedShift2('tarde')}
                                                        style={{ width: "20px" }}
                                                    />
                                                    Tarde
                                                </label>
                                                <label htmlFor="integral" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <input
                                                        type="checkbox"
                                                        name="integral"
                                                        id="integral"
                                                        checked={selectedShift2 === 'integral'}
                                                        onChange={() => setSelectedShift2('integral')}
                                                        style={{ width: "20px" }}
                                                    />
                                                    Integral
                                                </label>
                                                <label htmlFor="semintegralmanha" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <input
                                                        type="checkbox"
                                                        name="semintegralmanha"
                                                        id="semintegralmanha"
                                                        checked={selectedShift2 === 'semi-integral manhã'}
                                                        onChange={() => setSelectedShift2('semi-integral manhã')}
                                                        style={{ width: "20px" }}
                                                    />
                                                    Semi-integral manhã
                                                </label>
                                                <label htmlFor="semintegraltarde" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <input
                                                        type="checkbox"
                                                        name="semintegraltarde"
                                                        id="semintegraltarde"
                                                        checked={selectedShift2 === 'semi-integral tarde'}
                                                        onChange={() => setSelectedShift2('semi-integral tarde')}
                                                        style={{ width: "20px" }}
                                                    />
                                                    Semi-integral Tarde
                                                </label>
                                            </label>
                                        </li>
                                        <li style={{ alignItems: 'start' }}>
                                            <label htmlFor="sangue">Tipo sanguíneo do Aluno</label>
                                            <select
                                                name="sangue"
                                                id="sangue"
                                                value={tiposanguinio2}
                                                onChange={(e) => setTipoSanguinio2(e.target.value)}
                                                required
                                            >
                                                <option value="">Selecione o tipo sanguíneo</option>
                                                <option value="A+">A+</option>
                                                <option value="A-">A-</option>
                                                <option value="B+">B+</option>
                                                <option value="B-">B-</option>
                                                <option value="AB+">AB+</option>
                                                <option value="AB-">AB-</option>
                                                <option value="O+">O+</option>
                                                <option value="O-">O-</option>
                                                <option value="Não sei informar o tipo sanguíneo no momento, mas me comprometo a informar a Escola assim que possível.">Não sei informar o tipo sanguíneo no momento, mas me comprometo a informar a Escola assim que possível.</option>
                                            </select>
                                        </li>

                                        <li style={{ alignItems: "start" }}>
                                            <label htmlFor="medicamento">Medicamento de Emergência</label>
                                            <input
                                                type="text"
                                                name="medicamento"
                                                id="medicamento"
                                                value={emergencia2}
                                                onChange={(e) => setEmergencia2(e.target.value)}
                                                required
                                            />
                                        </li>
                                        <li style={{ alignItems: 'start' }}>
                                            <label>Bairro:</label>
                                            <input
                                                type="text"
                                                value={bairro2}
                                                onChange={(e) => setBairro2(e.target.value)}
                                            />
                                        </li>
                                        <li style={{ alignItems: 'start' }}>
                                            <label>Endereço completo:</label>
                                            <input
                                                type="text"
                                                value={endereco2}
                                                onChange={(e) => setEndereco2(e.target.value)}
                                            />
                                        </li>
                                        <li style={{ alignItems: 'start' }}>
                                            <label>CEP:</label>
                                            <input
                                                type="text"
                                                value={CEP2}
                                                onChange={(e) => setCEP2(e.target.value)}
                                            />
                                        </li>
                                        <li style={{ alignItems: 'start' }}>
                                            <label htmlFor={`vencimento`} style={{ fontWeight: 'bold', fontSize: '13pt', fontFamily: 'arial' }}>Vencimento</label>
                                            <select
                                                name={`vencimento`}
                                                id={`vencimento`}
                                                value={vencimento2}
                                                onChange={(e) => setVencimento2(e.target.value)}
                                                required
                                                style={{ width: "75%" }}
                                            >
                                                <option value="">Selecione o seu Vencimento</option>
                                                <option value="Dia 5">Dia 5</option>
                                                <option value="Dia 10">Dia 10</option>
                                                <option value="Dia 15">Dia 15</option>
                                            </select>
                                        </li>
                                        <li style={{ alignItems: "start" }}>
                                            <label htmlFor="overview">Informações Gerais do Aluno(opcional):</label>
                                            <textarea className="textarea"
                                                name="overview"
                                                id="overview"
                                                rows="3"
                                                value={overView2}
                                                onChange={(e) => setOverView2(e.target.value)}
                                            />
                                        </li>
                                        {/* Adicione outros campos para o segundo irmão conforme necessário */}
                                    </>
                                )}
                            </ul>
                        </div>
                        {/* Dados Responsaveis */}
                        <div className='responsaveis'>
                            {/* Responsavel principal */}
                            <ul >
                                <h5 className="resposaveis-ul-h5">Dados dos responsáveis :</h5>
                                <h5 style={{ color: "gray", borderBottom: "2px solid gray", paddingBottom: "5px" }}>Dados da mãe:</h5>
                                <li style={{ alignItems: 'start' }}>
                                    <label htmlFor={`nomeResponsavel`}>Nome do Responsável</label>
                                    <input
                                        type="text"
                                        name={'nomeResponsavel'}
                                        id={'nomeResponsavel'}
                                        value={nomeResponsavel}
                                        onChange={(e) => setNomeResponsavel(e.target.value)}
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label htmlFor={`cpfResponsavel`}>CPF do Responsável</label>
                                    <input
                                        type="text"
                                        name={'cpfResponsavel'}
                                        id={'cpfResponsavel'}
                                        value={formattedCPFResponsavel}
                                        maxLength={11}
                                        onChange={(e) => setCpfResponsavel(e.target.value)}
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label htmlFor={`emailResponsavel`}>E-mail do Responsável</label>
                                    <input
                                        type="email"
                                        name={'emailResponsavel'}
                                        id={'emailResponsavel'}
                                        value={emailResponsavel}
                                        onChange={(e) => setEmailResponsavel(e.target.value)}
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label htmlFor={`parentescoResponsavel`}>Parentesco</label>
                                    <select
                                        name={'parentescoResponsavel'}
                                        id={'parentescoResponsavel'}
                                        value={parentescoResponsavel}
                                        onChange={(e) => setParentescoResponsavel(e.target.value)}
                                    >
                                        <option value="">Selecione um parentesco</option>
                                        <option value="pai">Pai</option>
                                        <option value="mãe">Mãe</option>
                                        <option value="avô">Avô</option>
                                        <option value="avó">Avó</option>
                                    </select>
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label htmlFor={`profissaoResponsavel`}>Profissão do Responsável</label>
                                    <input
                                        type="text"
                                        name={'profissaoResponsavel'}
                                        id={'profissaoResponsavel'}
                                        value={profisaoResponsavel}
                                        onChange={(e) => setprofisaoResponsavel(e.target.value)}
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label htmlFor={`telefoneResponsavel`}>Telefone do Responsável</label>
                                    <input
                                        type="text"
                                        name={'telefoneResponsavel'}
                                        id={'telefoneResponsavel'}
                                        value={formattedTelResponsavel}
                                        maxLength={11}
                                        onChange={(e) => setTelefoneResponsavel(e.target.value)}
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label htmlFor={`responsavelPrincipal`} style={{ fontWeight: 'bold', fontSize: '11pt', fontFamily: 'arial' }}>Responsável financeiro:</label>
                                    <select
                                        name={`responsavelPrincipal`}
                                        id={`responsavelPrincipal`}
                                        value={responsavelPrincipal}
                                        onChange={(e) => {
                                            console.log("Selected value:", e.target.value);
                                            setResponsavelPrincipal(e.target.value);
                                        }}
                                        style={{ width: "90%" }}
                                    >
                                        <option value="">Selecione a alternativa que corresponde à sua situação.</option>
                                        <option value="Sim">Sim</option>
                                        <option value="Não">Não</option>
                                    </select>
                                </li>
                            </ul>
                            {/* Responsavel1 */}
                            <ul className="">
                                <h5 style={{ color: "gray", borderBottom: "2px solid gray", paddingBottom: "5px" }}>Dados do pai:</h5>
                                <li style={{ alignItems: 'start' }}>
                                    <label htmlFor={`nomeResponsavel1`}>Nome do Responsável</label>
                                    <input
                                        type="text"
                                        name={'nomeResponsavel1'}
                                        id={'nomeResponsavel1'}
                                        value={responsavel1.nomeResponsavel}
                                        onChange={(e) => setResponsavel1({ ...responsavel1, nomeResponsavel: e.target.value })}
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label htmlFor={`cpfResponsavel1`}>CPF do Responsável</label>
                                    <input
                                        type="text"
                                        name={'cpfResponsavel1'}
                                        id={'cpfResponsavel1'}
                                        value={formattedCPFResponsavel1}
                                        maxLength={11}
                                        onChange={(e) => setResponsavel1({ ...responsavel1, cpfResponsavel: e.target.value })}
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label htmlFor={`emailResponsavel1`}>E-mail do Responsável</label>
                                    <input
                                        type="email"
                                        name={'emailResponsavel1'}
                                        id={'emailResponsavel1'}
                                        value={responsavel1.emailResponsavel}
                                        onChange={(e) => setResponsavel1({ ...responsavel1, emailResponsavel: e.target.value })}
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label htmlFor={`parentescoResponsavel1`}>Parentesco</label>
                                    <select
                                        name={'parentescoResponsavel1'}
                                        id={'parentescoResponsavel1'}
                                        value={responsavel1.parentescoResponsavel}
                                        onChange={(e) => setResponsavel1({ ...responsavel1, parentescoResponsavel: e.target.value })}
                                    >
                                        <option value="">Selecione um parentesco</option>
                                        <option value="pai">Pai</option>
                                        <option value="mãe">Mãe</option>
                                        <option value="avô">Avô</option>
                                        <option value="avó">Avó</option>
                                    </select>
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label htmlFor={`profisaoResponsavel1`}>Profissão do Responsável</label>
                                    <input
                                        type="text"
                                        name={'profisaoResponsavel1'}
                                        id={'profisaoResponsavel1'}
                                        value={responsavel1.profisaoResponsavel}
                                        onChange={(e) => setResponsavel1({ ...responsavel1, profisaoResponsavel: e.target.value })}
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label htmlFor={`telefoneResponsavel1`}>Telefone do Responsável</label>
                                    <input
                                        type="text"
                                        name={'telefoneResponsavel1'}
                                        id={'telefoneResponsavel1'}
                                        value={formattedTelResponsavel1}
                                        maxLength={11}
                                        onChange={(e) => setResponsavel1({ ...responsavel1, telefoneResponsavel: e.target.value })}
                                    />
                                </li>
                                <li style={{ alignItems: 'start' }}>
                                    <label htmlFor={`responsavelPrincipal1`} style={{ fontWeight: 'bold', fontSize: '11pt', fontFamily: 'arial' }}>Responsável financeiro:</label>
                                    <select
                                        name={`responsavelPrincipal1`}
                                        id={`responsavelPrincipal1`}
                                        value={responsavel1.responsavelPrincipal}
                                        onChange={(e) => setResponsavel1({ ...responsavel1, responsavelPrincipal: e.target.value })}
                                        style={{ width: "90%" }}
                                    >
                                        <option value="">Selecione a alternativa que corresponde à sua situação.</option>
                                        <option value="Sim">Sim</option>
                                        <option value="Não">Não</option>
                                    </select>
                                </li>
                                {showResponsavel2 ? <h4 style={{ color: "red" }}>Retire os dados do responsável (Opcional)</h4> : <h4 style={{ color: "red" }}>Acrescente os dados de mais um responsável (opcional)</h4>}
                                <button className='button' onClick={handleToggleResponsavel2}>
                                    {showResponsavel2 ? '-' : '+'}
                                </button>
                            </ul>
                            {/* Responsavel2 */}
                            {showResponsavel2 && (
                                <ul className="">
                                    <h5 style={{ color: "gray", borderBottom: "2px solid gray", paddingBottom: "5px" }}>Responsável(opcional):</h5>
                                    <li style={{ alignItems: 'start' }}>
                                        <label htmlFor={`nomeResponsavel2`}>Nome do Responsável</label>
                                        <input
                                            type="text"
                                            name={'nomeResponsavel2'}
                                            id={'nomeResponsavel2'}
                                            value={responsavel2.nomeResponsavel}
                                            onChange={(e) => setResponsavel2({ ...responsavel2, nomeResponsavel: e.target.value })}

                                        />
                                    </li>
                                    <li style={{ alignItems: 'start' }}>
                                        <label htmlFor={`cpfResponsavel2`}>CPF do Responsável</label>
                                        <input
                                            type="text"
                                            name={'cpfResponsavel2'}
                                            id={'cpfResponsavel2'}
                                            value={formattedCPFResponsavel2}
                                            maxLength={11}
                                            onChange={(e) => setResponsavel2({ ...responsavel2, cpfResponsavel: e.target.value })}

                                        />
                                    </li>
                                    <li style={{ alignItems: 'start' }}>
                                        <label htmlFor={`emailResponsavel2`}>E-mail do Responsável</label>
                                        <input
                                            type="email"
                                            name={'emailResponsavel2'}
                                            id={'emailResponsavel2'}
                                            value={responsavel2.emailResponsavel}
                                            onChange={(e) => setResponsavel2({ ...responsavel2, emailResponsavel: e.target.value })}

                                        />
                                    </li>
                                    <li style={{ alignItems: 'start' }}>
                                        <label htmlFor={`parentescoResponsavel2`}>Parentesco</label>
                                        <select
                                            name={'parentescoResponsavel2'}
                                            id={'parentescoResponsavel2'}
                                            value={responsavel2.parentescoResponsavel}
                                            onChange={(e) => setResponsavel2({ ...responsavel2, parentescoResponsavel: e.target.value })}
                                        >
                                            <option value="">Selecione um parentesco</option>
                                            <option value="pai">Pai</option>
                                            <option value="mãe">Mãe</option>
                                            <option value="avô">Avô</option>
                                            <option value="avó">Avó</option>
                                        </select>
                                    </li>
                                    <li style={{ alignItems: 'start' }}>
                                        <label htmlFor={`profissaoResponsavel2`}>Profissão do Responsável</label>
                                        <input
                                            type="text"
                                            name={'profissaoResponsavel2'}
                                            id={'profissaoResponsavel2'}
                                            value={responsavel2.profissaoResponsavel}
                                            onChange={(e) => setResponsavel2({ ...responsavel2, profissaoResponsavel: e.target.value })}
                                        />
                                    </li>
                                    <li style={{ alignItems: 'start' }}>
                                        <label htmlFor={`telefoneResponsavel2`}>Telefone do Responsável</label>
                                        <input
                                            type="text"
                                            name={'telefoneResponsavel2'}
                                            id={'telefoneResponsavel2'}
                                            value={formattedTelResponsavel2}
                                            maxLength={11}
                                            onChange={(e) => setResponsavel2({ ...responsavel2, telefoneResponsavel: e.target.value })}
                                        />
                                    </li>
                                    <li style={{ alignItems: 'start' }}>
                                        <label htmlFor={`responsavelPrincipal2`} style={{ fontWeight: 'bold', fontSize: '11pt', fontFamily: 'arial' }}>Responsável financeiro:</label>
                                        <select
                                            name={`responsavelPrincipal2`}
                                            id={`responsavelPrincipal2`}
                                            value={responsavel2.responsavelPrincipal}
                                            onChange={(e) => setResponsavel2({ ...responsavel2, responsavelPrincipal: e.target.value })}
                                            style={{ width: "90%" }}
                                        >
                                            <option value="">Selecione a alternativa que corresponde à sua situação.</option>
                                            <option value="Sim">Sim</option>
                                            <option value="Não">Não</option>
                                        </select>
                                    </li>
                                    {showResponsavel2 ? <h4 style={{ color: "red" }}>Retire os dados do responsável (Opcional)</h4> : <h4 style={{ color: "red" }}>Acrescente os dados de mais um responsável (Opcional)</h4>}
                                    <button className='button' onClick={handleToggleResponsavel2}>
                                        {showResponsavel2 ? '-' : '+'}
                                    </button>
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className='form-opinion' style={{ marginTop: "10px", width: "90%" }}>
                        <ul>
                            <li style={{ alignItems: 'start' }}>
                                <h5>Como chegou ao colégio?</h5>
                                <label htmlFor="google">
                                    <input
                                        type="checkbox"
                                        name="google"
                                        id="google"
                                        checked={comoChegou.google}
                                        onChange={(e) => setComoChegou({ ...comoChegou, google: e.target.checked })}
                                    />
                                    Google
                                </label>
                                <label htmlFor="instagram">
                                    <input
                                        type="checkbox"
                                        name="instagram"
                                        id="instagram"
                                        checked={comoChegou.instagram}
                                        onChange={(e) => setComoChegou({ ...comoChegou, instagram: e.target.checked })}
                                    />
                                    Instagram
                                </label>
                                <label htmlFor="facebook">
                                    <input
                                        type="checkbox"
                                        name="facebook"
                                        id="facebook"
                                        checked={comoChegou.facebook}
                                        onChange={(e) => setComoChegou({ ...comoChegou, facebook: e.target.checked })}
                                    />
                                    Facebook
                                </label>
                                <label htmlFor="tiktok">
                                    <input
                                        type="checkbox"
                                        name="tiktok"
                                        id="tiktok"
                                        checked={comoChegou.tiktok}
                                        onChange={(e) => setComoChegou({ ...comoChegou, tiktok: e.target.checked })}
                                    />
                                    Tiktok
                                </label>
                                <label htmlFor="indicacao">
                                    <input
                                        type="checkbox"
                                        name="indicacao"
                                        id="indicacao"
                                        checked={comoChegou.indicacao !== ''}
                                        onChange={(e) => setComoChegou({ ...comoChegou, indicacao: e.target.checked ? 'Preencher abaixo' : '' })}
                                    />
                                    Indicação:
                                </label>
                                {comoChegou.indicacao !== '' && (
                                    <div>
                                        <h7>Quem indicou ?</h7>
                                        <input
                                            type="text"
                                            name="indicacaoOption"
                                            value={comoChegou.indicacao === 'Preencher abaixo' ? '' : comoChegou.indicacao}
                                            onChange={(e) => setComoChegou({ ...comoChegou, indicacao: e.target.value })}
                                        />
                                    </div>
                                )}
                            </li>
                        </ul>
                    </div>
                    <button type="submit" className="button primary" style={{ background: 'red', width: '100px', alignSelf: "center", marginTop: "20px" }} disabled={isLoading} >
                        {isLoading ? 'Loading...' : 'Matricular'}
                    </button>
                </form>
            }
        </div >
    )
}

export default Form