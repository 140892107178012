import apiAxios from '../api/apiAxios';
import Cookies from 'js-cookie'; // Importe a biblioteca js-cookie
// CampanhaDeMatriculasAction.js

export const criarCampanhaDeMatriculas = (matriculaData) => async (dispatch) => {
    try {
        const response = await apiAxios.post('/api/campanhaDeMatriculas/inscricao', matriculaData);
        const data = response.data;
        console.log(data)

        // Configure o cookie após uma matrícula bem-sucedida
        
        Cookies.set('campanhaDeMatriculasData', JSON.stringify(data), { expires: 1 }); // O cookie expira em 1 dia

        // Dispare uma ação para carregar os dados da campanha de matrículas do cookie
        dispatch({
            type: 'LOAD_CAMPANHA_MATRICULAS_FROM_COOKIE',
            payload: data,
        });

        dispatch({
            type: 'CRIAR_CAMPANHA_SUCCESS',
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: 'CRIAR_CAMPANHA_ERROR',
            payload: error.message,
        });
    }
};

