import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { criarCampanhaDeMatriculas } from '../actions/CampanhaDeMatriculasAction';
import escola from '../assets/OIP.png';
import ReactLoading from 'react-loading';
import { Helmet } from 'react-helmet';

const CampanhaForm = () => {

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const [nome, setNome] = useState('');
    const [idade, setIdade] = useState('');
    const [serie, setSerie] = useState('');
    const [nomeResponsavel, setNomeResponsavel] = useState('');
    const [telefoneResponsavel, setTelefoneResponsavel] = useState('');
    const [whatsappResponsavel, setWhatsappResponsavel] = useState('');
    const [colegio, setColegio] = useState('');
    const [serie2024, setSerie2024] = useState('');
    const [restricaoAlimentar, setRestricaoAlimentar] = useState('');
    const [comorbidade, setComorbidade] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const matriculaData = {
            nome,
            idade,
            serie,
            nomeResponsavel,
            telefoneResponsavel,
            whatsappResponsavel,
            colegio,
            serie2024,
            restricaoAlimentar,
            comorbidade,
        };

        // Chame a action para criar a campanha de matrículas com os dados
        setIsLoading(true);
        await dispatch(criarCampanhaDeMatriculas(matriculaData));
        setIsLoading(false);
    };

    const tituloDaPagina = 'Inscrição sábado animado';

    return (
        <div style={{ height: "100%", padding: "20px", display: "flex", justifyContent: "center", alignItems: 'center' }}>
            <Helmet>
                <title>Inscrição sábado animado</title>
                <meta property="og:title" content={tituloDaPagina} />
            </Helmet>
            {isLoading ? <div style={{ height: "100vh", display: 'flex', alignItems: "center", justifyContent: "center" }}><ReactLoading type="spin" color="#FF0000" height={50} width={50} /></div> :
                <form className='form-container-campanha' onSubmit={handleSubmit}>
                    <div>
                        <img src={escola} style={{ width: "90px" }} alt="Escola Logo" />
                    </div>
                    <h5 style={{ display: 'flex', justifyContent: "center", alignItems: "center", color: "red" }}><p style={{ color: "red" }}> 🛝 INSCRIÇÃO</p> <p style={{ color: "blue" }}>🌞 SÁBADO</p> <p style={{ color: "green" }}> 🤹‍♂️ ANIMADO</p></h5>
                    <h5 style={{ background: "red", display: 'flex', justifyContent: "center", alignItems: "center", color: "white", marginTop: "-30px" }}>7 de outubro de 2023 <br /> 9h às 11h</h5>
                    <div>
                        <label htmlFor="nome">Nome do aluno:</label>
                        <input
                            type="text"
                            id="nome"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="idade">Idade:</label>
                        <input
                            type="number"
                            id="idade"
                            value={idade}
                            onChange={(e) => setIdade(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="serie">Série:</label>
                        <input
                            type="text"
                            id="serie"
                            value={serie}
                            onChange={(e) => setSerie(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="nomeResponsavel">Nome do responsável:</label>
                        <input
                            type="text"
                            id="nomeResponsavel"
                            value={nomeResponsavel}
                            onChange={(e) => setNomeResponsavel(e.target.value)}
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="telefoneResponsavel">Telefone do responsável:</label>
                        <input
                            type="text"
                            id="telefoneResponsavel"
                            value={telefoneResponsavel}
                            onChange={(e) => setTelefoneResponsavel(e.target.value)}
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="whatsappResponsavel">WhatsApp do responsável:</label>
                        <input
                            type="text"
                            id="whatsappResponsavel"
                            value={whatsappResponsavel}
                            onChange={(e) => setWhatsappResponsavel(e.target.value)}
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="colegio">Colégio que estuda :</label>
                        <input
                            type="text"
                            id="colegio"
                            value={colegio}
                            onChange={(e) => setColegio(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="serie2024">Série para 2024:</label>
                        <input
                            type="text"
                            id="serie2024"
                            value={serie2024}
                            onChange={(e) => setSerie2024(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="restricaoAlimentar">Tem alguma restrição alimentar ?</label>
                        <input
                            type="text"
                            id="restricaoAlimentar"
                            value={restricaoAlimentar}
                            onChange={(e) => setRestricaoAlimentar(e.target.value)}
                        />
                    </div>

                    <div>
                        <label htmlFor="comorbidade">Tem alguma comorbidade ?</label>
                        <input
                            type="text"
                            id="comorbidade"
                            value={comorbidade}
                            onChange={(e) => setComorbidade(e.target.value)}
                        />
                    </div>

                    <div>
                        <button className="button primary" style={{ background: 'red', width: '100px', alignSelf: "center", marginTop: "20px" }} type="submit">Enviar Inscrição</button>
                    </div>
                </form>
            }
        </div>
    );
}

export default CampanhaForm