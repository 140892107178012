export function formatCPF(cpf) {
  cpf = cpf.replace(/\D/g, '');
  cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  return cpf;
}

export const formatTelefone = (telefone) => {
  const cleaned = ('' + telefone).replace(/\D/g, ''); // Remove todos os caracteres não numéricos

  const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return cleaned; // Retorna o número de telefone sem formatação
};
