import Cookies from 'js-cookie';
import apiAxios from '../api/apiAxios';

// Ação para matricular um aluno em esportes
export const matricularAlunoEsporte = (matriculaEsporteData) => async (dispatch) => {
    try {
        // Enviar os dados da matrícula esportiva para a rota apropriada no servidor
        const { data } = await apiAxios.post('/api/matriculasEsportes/matricular', matriculaEsporteData);

        // Armazene os dados do usuário no cookie após o login
        const userInfoSport = JSON.stringify(data);
        Cookies.set('userInfoSport', userInfoSport, { path: '/fichaEsportes' });
        // Disparar uma ação Redux para atualizar o estado com os dados da matrícula
        
        dispatch({
            type: 'MATRICULA_ESPORTE_SUCCESS',
            payload: data,
        });
    } catch (error) {
        // Disparar uma ação Redux em caso de erro
        dispatch({
            type: 'MATRICULA_ESPORTE_ERROR',
            payload: error.message,
        });
    }
};
