const initialState = {
    loading: false,
    error: null,
    matriculaData: [],
    userData: null, // Adicione um novo campo para armazenar os dados do usuário
};

const MatriculaReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'MATRICULA_REQUEST':
            return {
                ...state,
                loading: true,
            };
        case 'MATRICULA_SUCCESS':
            return {
                ...state,
                loading: false,
                error: null,
                matriculaData: action.payload,
            };
        case 'MATRICULA_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case 'SET_USER_DATA':
            return {
                ...state,
                userData: action.payload,
            };
        default:
            return state;
    }
};

export default MatriculaReducer;
