import React from 'react'
import { Link } from 'react-router-dom';
import escola from '../assets/OIP.png';
import whatssap from '../assets/whatsapp.png';
import youtube from '../assets/youtube.png';
import site from '../assets/internet.png';
import tiktok from '../assets/tiktok.png';
import instagram from '../assets/instagram.png'
import { Helmet } from 'react-helmet';

const BeforeFormColonia = () => {
    return (
        <div className='Before-form'>
            <Helmet>
                <title>Inscrição colónia de férias</title>
            </Helmet>
            <div className='Before-form-component'>
                <div className="grid-container">
                    <div className="grid-item">
                        <img src={escola} style={{ width: "80px" }} alt="Escola Logo" />
                    </div>
                    <div className="grid-item">
                        <p className='B-F-G' style={{ color: "red" }}>Sua ficha da colônia de férias foi enviada com sucesso.🏅🏊🥋🎨!</p>
                        <p style={{ color: "blue" }}>Há 27 anos,construindo laços e saberes.</p>
                        <p style={{ color: "green" }}>Visite nossas redes sociais.</p>
                    </div>
                    <div className="grid-item">
                        <div className='nav-links'>
                            <Link className='nav-link' to="https://colegioalternativocolaco.com.br/"><img src={site} style={{ width: "38px" }} alt="Escola Logo" /></Link>
                            <Link className='nav-link' to="https://api.whatsapp.com/send?phone=5585988068633"><img src={whatssap} style={{ width: "40px" }} alt="Escola Logo" /></Link>
                            <Link className='nav-link' to="https://www.youtube.com/@colegioalternativocolaco"><img src={youtube} style={{ width: "40px" }} alt="Escola Logo" /></Link>
                            <Link className='nav-link' to="https://www.instagram.com/colegioalternativocolaco"><img src={instagram} style={{ width: "40px" }} alt="Escola Logo" /></Link>
                            <Link className='nav-link' to="https://www.tiktok.com/@colegioalternativocolaco?_t=8XxozM2TnMr&_r=1"><img src={tiktok} style={{ width: "40px" }} alt="Escola Logo" /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BeforeFormColonia