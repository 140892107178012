import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setUserData } from './actions/MatriculaAction';
import Cookies from 'js-cookie';

import Form from './screens/Form';
import FormEsportes from './screens/FormEsportes';
import CampanhaForm from './screens/CampanhaForm';
import BeforeForm from './screens/BeforeForm';
import BeforeFormCampanha from './screens/BeforeFormCampanha';

import NotFound from './screens/NotFound';
import { Helmet } from 'react-helmet-async';
import BeforeFormEsporte from './screens/BeforeFormEsporte';
import FormColonia from './screens/FormColonia';
import BeforeFormColonia from './screens/BeforeFormColonia';
import FormCatequese from './screens/FormCatequese';
import BeforeFormCatequese from './screens/BeforeFormCatequese';


function App() {
  const dispatch = useDispatch();

  // Recupere os dados do usuário do estado do Redux
  const userData = useSelector((state) => state.matricula.userData);
  const userDataEsporte = useSelector((state) => state.matriculaEsporte.matriculaEsporteData);
  const CampanhaMatriculas = useSelector((state) => state.campanhaDeMatriculas.campanhaDeMatriculasData.data);
  const CampanhaColonia = useSelector((state) => state.InscricaoColonia.inscricao)
  const userDataCatequese = useSelector((state) => state?.inscricaoCatequese?.inscricaoCatequese)


  // Verifique se o usuário está logado com base nos dados do usuário no estado do Redux
  const isUserLoggedIn = !!userData && !!userData.data;
  const isUserSportLoggedIn = !!userDataEsporte && !!userDataEsporte.data;
  const isUserLoggedInInCatequese = !!userDataCatequese;
  console.log('oooo', isUserLoggedInInCatequese)

  useEffect(() => {
    // Caso não haja informações de usuário no estado do Redux, verifique o cookie
    if (!isUserLoggedIn) {
      const userInfoFromCookie = Cookies.get('userInfo');
      console.log('User info from cookie:', userInfoFromCookie);
      const parsedUserInfo = userInfoFromCookie ? JSON.parse(userInfoFromCookie) : null;
      const { data: matriculaDataFromCookie } = parsedUserInfo || {};

      // Se houver informações do cookie, defina o estado do usuário no Redux
      if (matriculaDataFromCookie) {
        dispatch(setUserData(parsedUserInfo));
      } else {
        console.log('Usuário deslogado ou erro: Não logado');
      }
    }

    if (!isUserSportLoggedIn) {
      const userInfoSportFromCookie = Cookies.get('userInfoSport');
      console.log('User info from cookie:', userInfoSportFromCookie);
      const parsedUserSportInfo = userInfoSportFromCookie ? JSON.parse(userInfoSportFromCookie) : null;
      const { data: matriculaDataFromCookie } = parsedUserSportInfo || {};

      // Se houver informações do cookie, defina o estado do usuário no Redux
      if (matriculaDataFromCookie) {
        dispatch(setUserData(parsedUserSportInfo));
      } else {
        console.log('Usuário deslogado ou erro: Não logado');
      }
    }

    const isUserLoggedInInCampanha = !!CampanhaMatriculas && !!CampanhaMatriculas.data;

    if (!isUserLoggedInInCampanha) {
      // Redirecione ou realize qualquer outra ação necessária se o usuário não estiver logado em "campanhaDeMatriculas"
      console.log('Usuário não está logado em campanhaDeMatriculas');
    }

    const isUserLoggedInColonia = !!CampanhaColonia && !!CampanhaColonia.data;

    if (!isUserLoggedInColonia) {
      // Redirecione ou realize qualquer outra ação necessária se o usuário não estiver logado em "campanhaDeMatriculas"
      console.log('Usuário não está logado em inscricão colonia.');
    }

  }, [isUserLoggedIn, isUserSportLoggedIn, CampanhaColonia, dispatch, CampanhaMatriculas]);

  const cookies = useSelector(state => state)
  console.log(cookies)
  return (
    <div className="App">
      <Router>
        <Routes>
          {isUserLoggedIn ? (
            <Route path="/" element={<BeforeForm />} />
          ) : (
            <Route path="/" element={<Form />} />
          )}
          <Route path="/before" element={<BeforeForm />} />

          {isUserSportLoggedIn ? (
            <Route path="/fichaEsportes" element={<BeforeFormEsporte />} />
          ) : (
            <Route path="/fichaEsportes" element={<FormEsportes />} />
          )}

          {CampanhaMatriculas ? (
            <Route path="/sabadoanimado" element={<BeforeFormCampanha />} />
          ) : (
            <Route path="/sabadoanimado" element={<CampanhaForm />} />
          )}

          {
            CampanhaColonia ? (
              <Route path="/fichacolonia" element={<BeforeFormColonia />} />
            ) : (
              <Route path='/fichacolonia' element={<FormColonia />}></Route>
            )
          }

          {isUserLoggedInInCatequese ? (
            <Route path="/fichaCatequese" element={<BeforeFormCatequese />} />
          ) : (
            <Route path="/fichaCatequese" element={<FormCatequese />} />
          )}

          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
