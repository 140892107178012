import axios from 'axios';
import Cookies from 'js-cookie';
import apiAxios from '../api/apiAxios';

// Ação assíncrona para criar uma inscrição na colônia de férias
export const criarInscricao = (inscricaoData) => async (dispatch) => {
    try {
        // Fazer a solicitação POST para a API do servidor
        const response = await apiAxios.post('/api/matriculaColonia/inscricao', inscricaoData); // Certifique-se de usar a rota correta
        const data = response.data;
        Cookies.set('InscricaocoloniaData', JSON.stringify(data), { expires: 1 });
        // Se a inscrição for criada com sucesso, você pode despachar uma ação de sucesso
        dispatch({
            type: 'CRIAR_INSCRICAO_SUCESSO',
            payload: data, // Se você desejar retornar algum dado da resposta
        });

        // Você pode também adicionar lógica de redirecionamento para uma página de confirmação ou fazer algo mais aqui

    } catch (error) {
        // Se ocorrer um erro, você pode despachar uma ação de erro
        dispatch({
            type: 'CRIAR_INSCRICAO_ERRO',
            payload: error.response.data, // Pode ser útil para exibir mensagens de erro
        });
    }
};
