import Cookies from 'js-cookie';
import apiAxios from '../api/apiAxios';

// Action para definir o estado do usuário
export const setUserData = (userData) => ({
    type: 'SET_USER_DATA',
    payload: userData,
});

// Função para fazer login e definir o estado do usuário
export const matricularAluno = (matriculaData) => async (dispatch) => {
    try {
        const { data } = await apiAxios.post('/api/matriculas/matricular', matriculaData);

        // Armazene os dados do usuário no cookie após o login
        const userInfo = JSON.stringify(data);
        Cookies.set('userInfo', userInfo, { path: '/' });

        // Defina o estado do usuário no Redux
        dispatch(setUserData(data));

        dispatch({
            type: 'MATRICULA_SUCCESS',
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: 'MATRICULA_ERROR',
            payload: error.message,
        });
    }
};